
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as index8PyZaqB9wu_45iDMoGOtNIRqURxR5MQZQwFEjnwazJ2NcMeta } from "/builds/bazinga/exped/frontend/pages/faqs/index.vue?macro=true";
import { default as indexJcitJ0GG0LIyS_45sjhOIPg3LA9PhR66xmKAg1umwbBhcMeta } from "/builds/bazinga/exped/frontend/pages/groups/index.vue?macro=true";
import { default as index_nQvcRSv5sRM_qV9RuXGKklGI2lw36MFunwz2Ppk7RIMeta } from "/builds/bazinga/exped/frontend/pages/sitemap/index.vue?macro=true";
import { default as indexDS1_45YsH2r90J2Kfvu6GZol0g5Lm3G0ifftbQzRrOMI8Meta } from "/builds/bazinga/exped/frontend/pages/newsroom/index.vue?macro=true";
import { default as indexFdeuvjpF5u4LE5hNuZv6VO_45swIoxHIExXeDFkKafYKoMeta } from "/builds/bazinga/exped/frontend/pages/[...slug]/index.vue?macro=true";
import { default as indexnl_45FPk8IfIRy5ODxMWI4SsKu9E8y2SMbDQw5nXtx6fkMeta } from "/builds/bazinga/exped/frontend/pages/media/[id]/index.vue?macro=true";
import { default as index_45djvFoJVhCt1NLHFMRDQSUGpCWwmTXf05_45cPZ2nno_4Meta } from "/builds/bazinga/exped/frontend/pages/products/[category]/index.vue?macro=true";
import { default as indexSrJ31pv2S33bo_A0yQGeBrDJMMmqGv1XxevuhtvzjIsMeta } from "/builds/bazinga/exped/frontend/pages/products/[category]/[product]/index.vue?macro=true";
import { default as component_45stubE_oLbfXhIVFi9pEmm9gQHGEj8HEdcfaPF8CkL7XaE5AMeta } from "/builds/bazinga/exped/frontend/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubE_oLbfXhIVFi9pEmm9gQHGEj8HEdcfaPF8CkL7XaE5A } from "/builds/bazinga/exped/frontend/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "faqs",
    path: "/de/faqs",
    meta: index8PyZaqB9wu_45iDMoGOtNIRqURxR5MQZQwFEjnwazJ2NcMeta || {},
    component: () => import("/builds/bazinga/exped/frontend/pages/faqs/index.vue")
  },
  {
    name: "groups",
    path: "/groups",
    meta: indexJcitJ0GG0LIyS_45sjhOIPg3LA9PhR66xmKAg1umwbBhcMeta || {},
    component: () => import("/builds/bazinga/exped/frontend/pages/groups/index.vue")
  },
  {
    name: "sitemap",
    path: "/sitemap",
    component: () => import("/builds/bazinga/exped/frontend/pages/sitemap/index.vue")
  },
  {
    name: "newsroom",
    path: "/de/newsroom",
    meta: indexDS1_45YsH2r90J2Kfvu6GZol0g5Lm3G0ifftbQzRrOMI8Meta || {},
    component: () => import("/builds/bazinga/exped/frontend/pages/newsroom/index.vue")
  },
  {
    name: "drupal-route",
    path: "/:slug(.*)*",
    meta: indexFdeuvjpF5u4LE5hNuZv6VO_45swIoxHIExXeDFkKafYKoMeta || {},
    component: () => import("/builds/bazinga/exped/frontend/pages/[...slug]/index.vue")
  },
  {
    name: "media-id",
    path: "/media/:id()",
    component: () => import("/builds/bazinga/exped/frontend/pages/media/[id]/index.vue")
  },
  {
    name: "product-category-list",
    path: "/en/products/:category",
    meta: index_45djvFoJVhCt1NLHFMRDQSUGpCWwmTXf05_45cPZ2nno_4Meta || {},
    component: () => import("/builds/bazinga/exped/frontend/pages/products/[category]/index.vue")
  },
  {
    name: "product-detail",
    path: "/en/products/:category/:product",
    meta: indexSrJ31pv2S33bo_A0yQGeBrDJMMmqGv1XxevuhtvzjIsMeta || {},
    component: () => import("/builds/bazinga/exped/frontend/pages/products/[category]/[product]/index.vue")
  },
  {
    name: component_45stubE_oLbfXhIVFi9pEmm9gQHGEj8HEdcfaPF8CkL7XaE5AMeta?.name,
    path: "/cart",
    component: component_45stubE_oLbfXhIVFi9pEmm9gQHGEj8HEdcfaPF8CkL7XaE5A
  },
  {
    name: component_45stubE_oLbfXhIVFi9pEmm9gQHGEj8HEdcfaPF8CkL7XaE5AMeta?.name,
    path: "/user/login",
    component: component_45stubE_oLbfXhIVFi9pEmm9gQHGEj8HEdcfaPF8CkL7XaE5A
  }
]